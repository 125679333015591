//$Id$
//store.unregisterModel("user")
store.registerModel("user", { //No I18N
    fields: Lyte.attr('array'),//No I18N
    name: Lyte.attr('string'),//No I18n
    zuid: Lyte.attr('string'), //No I18n
    customize_info : Lyte.attr('object'), //No I18n
    alias: Lyte.attr('string'), //No I18n
    confirm: Lyte.attr('boolean'), //No I18n
    date_format: Lyte.attr('string'), //No I18n
    email: Lyte.attr('string'), //No I18n
    first_name: Lyte.attr('string'), //No I18n
    full_name: Lyte.attr('string'), //No I18n
    last_name: Lyte.attr('string'), //No I18nNo I18n
    status: Lyte.attr('string'), //No I18n
    language: Lyte.attr('string'), //No I18n
    locale: Lyte.attr('string'), //No I18n
    country_locale: Lyte.attr('string'), //No I18n
    time_format: Lyte.attr('string'), //No I18n
    time_zone: Lyte.attr('string'), //No I18n
    Currency: Lyte.attr("string"), //NO I18n
}, {
    actions: {

    }
});