// $Id$
//store.unregisterSerializer("custom_view")
store.registerSerializer("custom_view", {  //No I18N
	normalizeResponse: function (modelName, type, payLoad) {
		if (!payLoad) {
			payLoad = [];
			return payLoad;
		}


		if (type === "findRecord") {
			if (payLoad.custom_views) { //No I18N
				payLoad.custom_views = payLoad.custom_views[0];
			} else {
				payLoad = {};
			}
		}
		return payLoad;

	},
});