//$Id$
//store.unregisterModel("layout")
store.registerModel("layout", {	//No I18N
    created_by: Lyte.attr('string'),//No I18N
    id: Lyte.attr('string', { primaryKey: true }),//No I18N
    modified_by: Lyte.attr('string'),//No I18N
    modified_time: Lyte.attr('string'),//No I18N
    name: Lyte.attr('string'),//No I18N
    status: Lyte.attr('number'),//No I18N
    created_time: Lyte.attr('string'),//No I18N
    parent_layout: Lyte.attr("string"),  //No I18N
    profiles: Lyte.attr('array'),//No I18N

    sections: Lyte.hasMany('section'),//No I18N
    module: Lyte.belongsTo('module'),//No I18N
    fields: Lyte.hasMany('field'),//No I18N
    layout_rule: Lyte.hasMany("layout_rule"),//No I18n
    validation_rule: Lyte.hasMany("validation_rule"),//No I18n

    didLoad: function (record) {
        record = (this instanceof Record) ? this : record;
        Lyte.registeredMixins["crm-crux-module-mixin"].addSubformFieldsintoLyteModel(record.sections);//no i18n
    }.on("add")
}, {
    actions: {

    }
});
