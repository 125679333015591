//$Id$
//store.unregisterAdapter("note")
store.registerAdapter("note", { //NO I18n
	namespace: "crm/v2.2", //NO I18n
	buildURL: function (modelName, type, queryParams, payLoad, url, actionName, customData) {
		var apiName = modelName[0].toUpperCase() + modelName.substr(1) + "s";
		url = url.replace(modelName, apiName); //NO I18n
		//eslint-disable-next-line @zoho/zstandard/proper-usage-of-if
		if (type === "findAll") { //NO I18n
			if (customData && customData.module && customData.entityId) { //eslint-disable-line @zoho/zstandard/proper-usage-of-if
				url = url.replace(apiName, ""); //NO I18n
				url += customData.module + "/" + customData.entityId + "/" + apiName; //NO I18n
			}
		} else if (type === "deleteRecord") {
			url = url.slice(0, url.lastIndexOf(apiName));
			url += payLoad.$se_module + "/" + payLoad.Parent_Id.id + "/" + apiName;
			url = url + "?ids=" + customData.noteId; //No I18n
		} else if (type === "updateRecord" && customData.removeAttachment) {
			url += "/Attachments?ids=" + customData.ids; //NO i18n
		}
		else if (type === "createRecord") {
			url = url.replace(apiName, ""); //NO I18n
			url += payLoad.$se_module + "/" + payLoad.Parent_Id.id + "/" + apiName;
		}
		else if (type === "updateRecord") {
			let record = store.peekRecord('note', payLoad.id); //No I18N
			if (record && record.id) {
				url = url.substring(0, url.indexOf(apiName));
				url += record.$se_module + "/" + record.Parent_Id.id + "/" + apiName;
			}
		}
		else if (type === "findRecord" && customData && customData.module && customData.entityId) {
			url = url.replace(apiName, customData.module + "/" + customData.entityId + "/" + apiName); //NO I18n
		}

		if (customData && customData.apiVersion) {
			url = url.replace('v2', customData.apiVersion); //no i18n
		}

		return url;
	},
	methodForRequest: function (method, type, queryParams, customData) {
		if (method === "PATCH") { //NO I18n
			method = "PUT"; //NO I18n
		}
		if (type === "updateRecord" && customData.removeAttachment) {
			method = "DELETE"; //NO I18n
		}
		return method;
	}
});