//$Id$
//store.unregisterModel("related_list")
store.registerModel("related_list", {//No I18N
    list_label: Lyte.attr('string'), //No I18N
    sequence_number: Lyte.attr("string"), //No I18N
    display_label: Lyte.attr("string"), //No I18N
    api_name: Lyte.attr("string"), //No I18N
    module: Lyte.attr("object"), //No I18N
    name: Lyte.attr("string"), //No I18N
    action: Lyte.attr("string"), //No I18N
    id: Lyte.attr("string", { primaryKey: true }), //No I18N
    href: Lyte.attr("string"), //No I18N
    type: Lyte.attr("string"), //No I18N
    visibility: Lyte.attr("number"), //No I18N  
    visible: Lyte.attr("boolean"),//No I18N  
    sort_order: Lyte.attr('string'), // No I18n
    connectedmodule: Lyte.attr('string'), //no i18n
    mxnfield: Lyte.attr('string'), //no i18n
    linkingmodule: Lyte.attr('string'), //no i18n
    record_operations: Lyte.attr('object'), //no i18n
    //    fields : Lyte.hasMany('field',{ serialize  : "record" }) //no i18n
    fields: Lyte.attr('array') //no i18n
});