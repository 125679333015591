//$Id$
//store.unregisterSerializer("validation_rule")
store.registerSerializer("validation_rule", {
    normalizeResponse: function (modelName, type, payLoad) {
        if (payLoad) {
            payLoad[modelName] = payLoad.validation_rules ? payLoad.validation_rules : [];
            delete payLoad.validation_rules;
            return payLoad;
        }
        payLoad[modelName] = [];
        return payLoad;
    },
    payloadKey: function (modelName) {
        if (modelName) {
            return modelName;
        }
    }
});
