//$Id$
//store.unregisterAdapter("user")
store.registerAdapter("user", {//No I18n
    xhrReq: new XMLHttpRequest(),
    namespace: "crm/v7", //No I18n
    buildURL: function (modelName, type, queryParams, payLoad, url) {
        url = url.replace(modelName, modelName + "s"); //NO I18n
        return url;
    },
    methodForRequest: function (method, type) {
        if (method === "PATCH" && type === "updateRecord") {
            return "PUT"; //NO i18n
        }
        return method;
    },
    processRequest: function (type, modelName, payload, snapshot, customData) {
        var _self = this;
        if (type === "action") {
            payload = JSON.parse(payload);
            if (payload.type === "search" || payload.type === "count") {
                return new Promise(function (resolve, reject) {
                    var xhr = _self.xhrReq;
                    var url = payload.type === "search" ? "/" + _self.namespace + "/users/search?" : "/" + _self.namespace + "/users/actions/count?"; //NO I18n
                    if (payload.type === "count" && customData) {
                        payload.customData = customData;
                    }
                    for (var k in payload.customData) {
                        if (k !== "tempApiVersion") {
                            url += k + "=" + encodeURIComponent(payload.customData[k]) + "&";
                        }
                    }
                    xhr.open("GET", url);
                    var hd = store.adapter.application.headersForRequest(type, undefined, payload.customData);
                    for (var key in hd) {
                        xhr.setRequestHeader(key, hd[key]);
                    }
                    xhr.onload = function () {
                        if (this.status >= 200 && this.status < 300) {
                            resolve(xhr.response);
                        } else {
                            reject({
                                status: this.status,
                                statusText: xhr.statusText
                            });
                        }
                    };
                    xhr.onerror = function () {
                        reject({
                            status: this.status,
                            statusText: xhr.statusText
                        });
                    };
                    xhr.send();
                });
            }
            else if (customData && customData.feature !== "subordinates") { //No I18n
                var url;
                return new Promise(function (resolve, reject) {
                    var xhr = _self.xhrReq;
                    xhr.open("POST", url);
                    var hd = store.adapter.application.headersForRequest();
                    for (var key in hd) {
                        xhr.setRequestHeader(key, hd[key]);
                    }
                    xhr.onload = function () {
                        if (this.status >= 200 && this.status < 300) {
                            resolve(xhr.response);
                        }
                        else if (this.status === 400 && xhr.response) {
                            reject({
                                status: this.status,
                                statusText: xhr.response

                            });
                        }
                        else {
                            reject({
                                status: this.status,
                                statusText: xhr.statusText
                            });
                        }
                    };
                    xhr.onerror = function () {
                        reject({
                            status: this.status,
                            statusText: xhr.statusText
                        });
                    };
                    xhr.send(JSON.stringify(customData));
                });
            }
        }
    },
    parseResponse: function (type, modelName, xhrObj, payLoad) {
        if (payLoad.get_assigned) {
            return payLoad.get_assigned;
        }
        return payLoad;
    }
});