//$Id$
//store.unregisterAdapter("layout");
store.registerAdapter("layout", {//No I18n
	namespace: "crm/v2.1/settings",//No I18n
	headersForRequest: function () {
		var headers = {
			"X-ZCSRF-TOKEN": csrfParamName + "=" + csrfToken //No I18N	
		};

		if (typeof crmZgid !== 'undefined' && crmZgid) {
			headers["X-CRM-ORG"] = crmZgid; //No I18N
		}
		return headers;
	},
	buildURL: function (modelName, type, queryParams, payLoad, url) {
		url = url.replace(modelName, modelName + "s");
		return url;
	}
});