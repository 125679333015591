//$Id$
//store.unregisterSerializer("field")
store.registerSerializer("field", {//No I18N
    serializeKey: function (modelName) {
        return modelName + "s"; //No I18N
    },
    normalizeResponse: function (modelName, type, payLoad, pkValue, status, headers, queryParams, customData) {
        var a = store.peekAll('module').filterBy({ api_name: queryParams.module }); //no i18n
        if (payLoad && payLoad.fields && a.length > 0) {
            let modInfo = a[0];
            if (customData && !customData.include_external_fields) {
                payLoad.fields = payLoad.fields.filter(field => field.external === null);
            }
            payLoad.fields.forEach(function (item) {
                item.module = [];
                item.module[0] = a[0] && a[0].id ? a[0].id : '';
                if (item.column_name === "FULLNAME" && ["Leads", "Contacts"].includes(modInfo.api_name)) {
                    item.field_label = item.display_label = I18n.getMsg("crm.label.vendor.name", modInfo.singular_label);//no i18n
                }
                if (type !== 'create' && type !== "update" && item.api_name.indexOf(".") > -1) {
                    item.api_name.replace(".", "__");
                }
            });
        }
        return this.$super.normalizeResponse(modelName, type, payLoad);
    }
});