//$Id$
//store.unregisterSerializer("module");
store.registerSerializer("module", { //No I18N
    serialize: function (type, data, records, customData, modelName, queryParams, actionName) {
        if (actionName === "view_preference_configurations") {
            var payLoad = {};
            payLoad.modules = [data];
            return payLoad;
        }
        var id = data.modules.id;
        if (id === undefined) {
            return data;
        }
        var moduleDetail = store.peekRecord('module', id); //No I18n
        data.modules.api_name = moduleDetail.api_name;
        if (type !== "updateRecord") {
            data.modules.api_name = moduleDetail.api_name;
        }
        data.modules = [data.modules];
        return data;
    },
    serializeKey: function (modelName) {
        return modelName + 's'; //no I18n
    },
    normalizeResponse: function (modelName, type, payLoad) {
        switch (type) {
            case "update": {
                if (payLoad && payLoad.modules && payLoad.modules.length > 0) {
                    payLoad.modules.forEach(function (module) {
                        module.id = module.details.id;
                        delete module.status;
                    });
                }
                break;
            }
            case "pushPayload":
            case "findAll":
                {
                    payLoad = type === "findAll" ? payLoad.modules : payLoad; //No I18N
                    payLoad = type === "findAll" ? { modules: payLoad } : payLoad;//No I18N
                }
                break;
        }
        if (type === "pushPayload") {
            return { modules: payLoad };
        }

        return payLoad;
    },
    normalize: function (modelName, type, snapShot, customData) {
        //to handle customView dropdown in activites module
        var mName = snapShot.module_name;
        if ((type === "findAll" || type === "findRecord") && (mName === 'Calls' || mName === 'Events' || mName === 'Tasks') && !Crm.isActivitySplitDone) {
            delete snapShot.custom_view;
        }
        if ((type === "findAll" || type === "findRecord") && snapShot.layouts) {
            var layoutsLen = snapShot.layouts.length, preventLayouts = [-3, -4];     //status -3 is Marked for delete (Hidden from the UI)
            for (var i = 0; i < layoutsLen; i++) {									   // status -4 is user defined downgraded layout.
                if (preventLayouts.indexOf(snapShot.layouts[i].status) !== -1) {
                    store.unloadRecord("layout", snapShot.layouts[i].id);
                    snapShot.layouts.splice(i, 1);
                    i--; layoutsLen--;
                    continue;
                }
                if (snapShot.id) {
                    snapShot.layouts[i].module = { id: snapShot.id };
                }
                this.setProperty(snapShot.layouts[i], customData);
            }
        }
        if (snapShot.fields && customData && !customData.getExternalFields) {
            var fieldsLength = snapShot.fields.length;
            var fields = [];
            var externalProp = "";
            var field = null;
            for (let i = 0; i < fieldsLength; i++) {
                field = snapShot.fields[i];
                externalProp = field.external;
                if (externalProp === null) {
                    fields.push(field);
                }
            }
            if (typeof fields === "object" && fields.length > 0) {
                snapShot.fields = fields;
            }
        }
        return snapShot;
    }
}, { mixins: ["crm-crux-module-mixin"] });//No I18n
if (typeof moduleDetailedInfo !== undefined &&
    moduleDetailedInfo.modules &&
    moduleDetailedInfo.modules.length) {
    store.pushPayload('module', moduleDetailedInfo.modules, true);//No I18n
}