//$Id$
//store.unregisterAdapter("validation_rule")
store.registerAdapter("validation_rule", {//No I18n
    namespace: 'crm/v2.1/settings',//No I18n
    buildURL: function (modelName, type, queryParams, payLoad, url) {
        url = url.replace('validation_rule', 'validation_rules');
        return url;
    },
    methodForRequest: function (method, type) {
        if (type === 'create') {
            return "POST";//no i18n
        }
        return method;
    },
    parseResponse: function (type, modelName, xhr, payload, queryParams) {
        if (payload && queryParams && queryParams.layout_id) {
            var layoutId = queryParams.layout_id;
            var len = payload.validation_rules ? payload.validation_rules.length : 0;
            for (var i = 0; i < len; i++) {
                payload.validation_rules[i].layout = layoutId;
            }
        }
        return payload;
    }
});
