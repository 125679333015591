/* $Id$ */
//store.unregisterSerializer("entity")
store.registerSerializer("entity", { //No I18N
    preserveTypes_CEC: ["date", "datetime", "multi-picklist", "phone", "time-in-hrs", "time-in-minutes", "tax"],//no i18n
    serializeKey: function (modelName, type, customData) {
        if (customData === undefined || (customData && customData.action !== 'convert' && customData.action !== 'mass_convert') && customData.action !== 'merge') {
            return 'data'; //No I18N
        }
    },
    normalize: function (modelName, type, data) {
        if (type === "findAll") {
            //To handle dirty values getting removed when same module lookup field used. So patching dirty values.
            var exitingRecord = store.peekRecord(modelName, data.id);
            if (exitingRecord && exitingRecord.$.isDirty()) {
                var fldName = exitingRecord.$.getDirtyAttributes()[0];
                var extValue = exitingRecord[fldName];
                var curntModel = store.modelFor(modelName);
                if (curntModel && curntModel.fieldList && curntModel.fieldList[fldName] &&
                    curntModel.fieldList[fldName].type === "relation"
                    && exitingRecord[fldName] && exitingRecord[fldName].mapBy) { //Maximum call stack size exceeded exception for subform in Edit page ---lyteCreate
                    extValue = exitingRecord[fldName].mapBy('id');
                }
                data[fldName] = extValue;
            }
        }
        var keys = Object.keys(data);
        var keyLength = keys.length;
        for (var ind = 0; ind < keyLength; ind++) {
            if (keys[ind].indexOf(".") > -1) {
                delete data[keys[ind]];
            }
        }
        return data;
    },
    serialize: function (type, payLoad, records, customData, modelName, queryParams, actionName) {
        if (customData && customData.$se_module) {
            payLoad.data.$se_module = customData.$se_module;
        }
        if (type !== "destroyRecord" && type !== "create" && payLoad && actionName !== "convert" && actionName !== "mass_convert" &&
            actionName !== "merge" && actionName !== "pre_validate") { //no i18n
            // fix for $se_module 
            if (records.$se_module && payLoad.data) {
                payLoad.data.$se_module = records.$se_module;
            }
            //clone new API support
            if (customData && customData.cloneData) {
                var finServerObj = {};
                for (var k1 in customData.cloneData.dataToSend) {
                    var fldProps = store.modelFor(modelName).fieldList[k1];
                    if (fldProps && fldProps.fieldType === "multiuserlookup" && !payLoad.data[k1]) {
                        finServerObj[k1] = [];
                    } else {
                        finServerObj[k1] = payLoad.data[k1];
                    }
                }
                payLoad.data = [finServerObj];
            } else {
                payLoad.data = [payLoad.data];
            }
        }
        if (actionName === "execute" && customData && customData.jsonData) {
            return { functions: customData.jsonData };
        }
        if (actionName === "change_owner" && customData && customData.qParams) {
            var pObject = {}, qp = customData.qParams;
            pObject.owner = { id: qp.owner_id };
            pObject.notify = qp.notify;
            if (qp.related_modules && qp.related_modules.length) {
                pObject.related_modules = qp.related_modules;
            } else {
                pObject.related_modules = [];
            }
            return pObject;
        }
        return payLoad;
    },
    normalizeResponse: function (modelName, type, payLoad) {
        function handleNullvaluesinRecord(_data) {
            if (_data) {
                var _keys = Object.keys(_data);
                var _keyLen = _keys.length;
                var _model = store.modelFor(modelName);
                var fieldList = _model.fieldList;
                if (!payLoad.meta) {
                    payLoad.meta = {};
                }
                if (!payLoad.meta.original) {
                    payLoad.meta.original = {};
                }
                if (!_data._$) {
                    _data._$ = {};
                }
                if (!_data._$.original) {
                    _data._$.original = {};
                }
                var preserveTypes = this.preserveTypes_CEC;
                for (var l = 0; l < _keyLen; l++) {
                    if (_data[_keys[l]] === null) { //removed since it was handled in store itself ----> reverted since it is breaking in default features. eg: ZCRM-129235
                        _data[_keys[l]] = undefined;
                    }
                    if (fieldList[_keys[l]] && (_data[_keys[l]] || _data[_keys[l]] === 0) && preserveTypes.indexOf(fieldList[_keys[l]].type) > -1) {
                        payLoad.meta.original[_keys[l]] = _data._$.original[_keys[l]] = _data[_keys[l]];
                    } else if (_data[_keys[l]] && _data[_keys[l]].length && fieldList[_keys[l]] && fieldList[_keys[l]].type === "relation" && fieldList[_keys[l]].relType === "hasMany") {
                        payLoad.meta.original[_keys[l]] = _data._$.original[_keys[l]] = Lyte.deepCopyObject(_data[_keys[l]].slice(0));
                    }
                }

                //to support null check for subform relations
                var _relations = _model.relations;
                for (var rel in _relations) {
                    var currRel = _relations[rel];
                    if (currRel && currRel.length && currRel[0].relType === "hasMany" &&
                        (_data[currRel[0].relKey] === null || _data[currRel[0].relKey] === undefined) &&
                        (_data.hasOwnProperty(currRel[0].relKey))) { //eslint-disable-line @zoho/zstandard/no-duplicate-null-check
                        _data[currRel[0].relKey] = [];
                    }
                }
            }
        };
        if (payLoad.data) {
            payLoad.data.forEach(function (item) {
                if (item.hasOwnProperty('transition')) { //no i18n
                    item.id = item.transition.id;
                }
                if (item.hasOwnProperty('$approval_state') && !(item.hasOwnProperty('$approved'))) {
                    if (item.$approval_state === 'approved' || item.$approval_state === 'review_process_pending' ||
                        item.$approval_state === 'review_process_rejected' || item.$approval_state === 'merge_pending') { // NO I18N
                        item.$approved = true;
                    } else {
                        item.$approved = false;
                    }
                }
            });
        }

        if (type === "createRecord" || type === "create" || type === 'updateRecord') {
            var a = {}; a[modelName] = {};
            a[modelName] = payLoad.data[0].details;
            return a;
        }
        if (type === "findAll" || type === "findRecord") {
            payLoad[modelName] = payLoad.data ? payLoad.data : [];
            delete payLoad.data;
            if (type === "findRecord") {
                var _fRdata = payLoad[modelName] ? payLoad[modelName][0] : undefined;
                handleNullvaluesinRecord.call(this, _fRdata);
            } else if (typeof payLoad[modelName] !== "undefined") {
                payLoad[modelName].forEach(function (_indRecord) {
                    handleNullvaluesinRecord.call(this, _indRecord);
                }.bind(this));
            }
        }
        return payLoad;
    },
    payloadKey: function (modelName, type, key, query, customdata) {
        if (customdata !== undefined && customdata.action === 'convert') {
            return '__conversion_options';//No I18N
        }
        return modelName;
    },
    extractMeta: function (payLoad) {
        return payLoad.info;
    }
});