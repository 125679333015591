//$Id$
//store.unregisterSerializer("note")
store.registerSerializer("note", {
	normalizeResponse: function (modelName, type, payLoad) {
		payLoad[modelName] = payLoad.data;
		if (type === "createRecord" || type === "updateRecord") {
			payLoad.data = payLoad.data[0].details;
		}
		return payLoad;
	},
	serialize: function (type, payLoad, records) {
		if (records.entity) {
			delete records.entity;
		}
		payLoad.data = [records];
		return payLoad;
	},
	serializeKey: function () {
		return "data"; //NO i18n
	},
	payloadKey: function () {
		return "data"; //NO i18n
	}
});