//$Id$
//store.unregisterSerializer("user")
store.registerSerializer("user", { //No I18N
    varPayloadKey: 'users',  //for lucene search //No I18N
    normalizeUserData: function (user) {
        for (var prop in user) {
            if (user[prop] === null) {
                user[prop] = undefined;
            }
        }
    },
    normalizeResponse: function (modelName, type, payLoad) {
        if (validationUtils.isEmpty(payLoad) && (type === "findRecord" || type === "findAll")) {
            payLoad.users = [];
        } else if (payLoad && payLoad.count !== undefined) {
            payLoad.meta = {
                count: payLoad.count
            };
            payLoad.users = [];
            delete payLoad.count;
        } else if (type === "findRecord" || type === "findAll") {
            var nameformat = Crm.userDetails.NAME_FORMAT.split(',');//No I18N
            var userLength = payLoad.users.length;
            var firstName = nameformat.indexOf("First Name");//No I18N
            var lastName = nameformat.indexOf("Last Name");//No I18N
            for (var i = 0; i < userLength; i++) {
                var userObject = payLoad.users[i];
                var fullname = userObject.last_name ? userObject.last_name : userObject.first_name;
                if (firstName > lastName) {
                    fullname = (userObject.first_name ? userObject.first_name : "") + " " + (userObject.last_name ? userObject.last_name : "");//No I18N
                }
                else {
                    fullname = (userObject.last_name ? userObject.last_name : "") + " " + (userObject.first_name ? userObject.first_name : "");//No I18N
                }
                payLoad.users[i].full_name = fullname.trim();
            }
        }
        var users = payLoad.users;
        if (users) {
            if (users.constructor === Array) {
                users.forEach(function (user) {
                    this.normalizeUserData(user);
                }.bind(this));
            } else if (users.constructor === Object) {
                this.normalizeUserData(users);
            }
        }
        return payLoad;
    },
    //for lucene search
    payloadKey: function () {
        return this.varPayloadKey;
    },
    normalize: function (modelName, type, payLoad) {
        if (payLoad) {
            var rec = store.peekRecord('user', payLoad.id);//No I18n
            if (!payLoad.image_link && rec && !rec.image_link) {
                payLoad.image_link = "";
            }
        }
        if (type === "findAll" && payLoad !== undefined) {
            var territory = payLoad.territories, len = territory ? territory.length : 0;
            if (len) {
                for (var i = 0; i < len; i++) {
                    if (Object.keys(territory[i]).includes("manager")) {
                        delete territory[i].manager;
                    }
                }
            }
            if(!payLoad.customize_info){
                payLoad.customize_info = {};
            }
        }
        return payLoad;
    }
});