//$Id$
//store.unregisterSerializer("layout_rule");
store.registerSerializer("layout_rule", {
    normalizeResponse: function (modelName, type, payLoad, pkValue, status, headers, queryParams, customData) {
        if (!payLoad || !Object.keys(payLoad).length) {
            return { layout_rule: [] };
        }
        var _respL = payLoad.rules.length;
        for (var i = 0; i < _respL; i++) {
            if (!payLoad.rules[i].id) {
                payLoad.rules[i].id = customData + "_" + i;
            }
            payLoad.rules[i].layout = customData;
        }
        return payLoad;
    },
    payloadKey: function () {
        return 'rules';//no i18n
    }
});