//$Id$
//store.unregisterAdapter("entity")
store.registerAdapter("entity", {//No I18n
    namespace: "crm/v2.2", //No I18n
    // reloadAll: function () {
    //     return true;
    // },
    methodForRequest: function (method, type, Competitors, customData, actionName) {
        if (type === "findAll") {
            method = "POST";//no i18n
        }
        if (customData && customData.type) {
            return customData.type;
        }
        if (type === "action") { //No I18n
            if (actionName === "count") {
                return "POST";//No I18n
            }
            return customData && customData.type ? customData.type : "GET"; //No I18n
        }
        return method;
    },
    buildURL: function (modelName, type, queryParams, payLoad, url, actionName, customData) {
        if (!queryParams) {
            queryParams = {};
        }
        if (!customData) {
            customData = {};
        }
        var modelDetail = store.peekRecord('module', modelName); //No I18n
        var model = modelDetail.api_name;
        if (customData.entityId) {
            model = model + "/" + customData.entityId;
        }
        url = url.replace(modelName, model);
        if (customData.type !== undefined && customData.type === "Search") {
            url = url + '/search'; //no i18n
            if (customData.from !== "Lookup") {
                url = url.replace('v2.2', 'v2');
            }
            queryParams.criteria = customData.criteria;
        }
        if (actionName === 'change_owner' && customData && customData.entityid) {
            // if (customData && customData.entityid) {
            url = url.replace('/actions/change_owner', '/' + customData.entityid + '/actions/change_owner');
            // }
        }
        if (actionName === "custom_buttons") {
            if (customData.isProcessFlowBtn) {
                url = url.replace('/v2.2', '/v6'); 	//No i18n
                url = url.replace('actions/custom_buttons', 'actions/execute_custom_buttons'); 	//No i18n
                queryParams.id = customData.button_id;

            }
            else {
                url += "/" + customData.button_id;
            }
        }
        else if (actionName === "count") {
            for (var key in customData) {
                queryParams[key] = customData[key];
            }
            customData.actionName = "count";//no i18n
        }

        if (actionName === "execute") {
            url = url.replace(url.slice(url.indexOf('/crm/'), url.indexOf('/actions') + 1), '/crm/v2/functions/');
        }
        if (type === "findAll") {
            url = url + "/bulk";//no i18n
        }
        if (type === "findAll" && customData.type === "Search" && customData.from === "Lookup") {
            url = url.replace('/bulk', '');
        }
        if (queryParams.fields) {
            queryParams.fields = queryParams.fields.toString();
        }
        if (queryParams && (queryParams.filters || queryParams.cross_filters) && !["timelines", "upcoming_actions", "journeys", "ownership_history", "picklist_tracker"].includes(actionName)) {
            var dataObj = {};
            dataObj.value = queryParams.filters && typeof queryParams.filters !== "string" ? JSON.stringify(queryParams.filters) : queryParams.filters;//no i18n
            Object.defineProperty(queryParams, '_filters', dataObj);//no i18n
            dataObj.value = queryParams.cross_filters && typeof queryParams.cross_filters !== "string" ? JSON.stringify(queryParams.cross_filters) : queryParams.cross_filters;//no i18n
            Object.defineProperty(queryParams, '_cross_filters', dataObj);//no i18n
            delete queryParams.filters; delete queryParams.cross_filters;
        }
        if (customData.type && customData.module && customData.id) {
            url = url + '/' + customData.module + '/' + customData.id;
        }
        //clone support
        if (customData.cloneData) {
            url += "/" + customData.cloneData.parent_id + "/actions/clone";
        }
        if (customData.version === 4) {
            url = url.replace('v2.2', 'v4'); // NO I18N
        }
        if (customData.invRECORDSversion) {
            url = url.replace('/v2.2/', '/' + customData.invRECORDSversion + '/');//no i18n
        }
        if (actionName === 'rlCount') { //No I18n
            url = url.replace('v2.2', 'v4'); // NO I18N
        }
        return url;
    },
    parseResponse: function (type, modelName, xhrObj, payLoad) {
        if (xhrObj.status === 204) {
            payLoad = this.super(arguments);//eslint-disable-line @zoho/zstandard/no-reserved-words
            payLoad.info = {};
        }
        return payLoad;
    },
    headersForRequest: function (type, action, customData) {
        var headers = this.$super.headersForRequest(type, action, customData);
        if (customData && customData["X-CRM-FEATURE-NAME"]) {
            headers["X-CRM-FEATURE-NAME"] = customData["X-CRM-FEATURE-NAME"];//No I18N
        }
        if (!headers.Range && customData && customData.subformNavigation) {
            headers.Range = customData.subformNavigation;
        }
        return headers;
    }
});