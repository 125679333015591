//$Id$
//store.unregisterSerializer("layout")
store.registerSerializer("layout", {//No I18n
	serialize: function (type, payLoad, records, customData, modelName, queryParams, actionName) {
		//		else if(actionName != 'associated' && actionName != 'layoutDeleteAction' &&  actionName != 'layoutDeactivateAction' &&  actionName != 'layoutActivateAction'  && actionName != 'layoutPermissionAction'){
		if (actionName !== 'associated' && !(customData && customData.noPayloadArray) && payLoad) {
			payLoad.layouts = [payLoad.layouts];
		}
		return payLoad;
	},
	normalize: function (modelName, type, payLoad, customData) {
		if ((type === "findAll" || type === "findRecord") && payLoad) {
			this.setProperty(payLoad, customData);
		}
		return payLoad;
	},
	serializeKey: function (modelName) {
		return modelName + "s"; //No I18N
	},
	normalizeResponse: function (modelName, type, payLoad, pkValue, status, headers, queryParams, customData) {
		function a(key) {
			if (key === "Potentials") {
				return queryParams.module === "Potentials";//No I18N
			}
			return moduleApiMapping[key] === queryParams.module;
		}
		function altFind(arr, callback) {
			var len = arr.length;
			for (var i = 0; i < len; i++) {
				var match = callback(arr[i]);
				if (match) {
					return arr[i];
				}
			}
		}
		var module;
		try {
			module = moduleRecordMapping[altFind(Object.keys(moduleApiMapping), a)].id;
		} catch (err) {
			if (customData && customData.clientPortalSettings && err.message === "Cannot read property 'id' of undefined") {
				crmui.showMsgBand("error", I18n.getMsg("portal.apiname.change.error", crmBasePath + '/settings/client-portal'), 10000);//No i18N
				throw err;
			} else {
				module = queryParams.module;
			}
		}
		if ((type === "findAll" || type === "findRecord") && payLoad.layouts) {
			var payLoadLength = payLoad.layouts.length, preventV2Layouts = ["hidden", "downgraded"], preventV1Layouts = [-3, -4]; //No I18n  
			for (var i = 0; i < payLoadLength; i++) {
				var sections = payLoad.layouts[i].sections;							   // status -4 is the downgraded layout. //status -3 is Marked for delete (Hidden from the UI)
				if (customData && !customData.include_external_fields && sections) {
					var sectionLength = sections.length;
					for (var j = 0; j < sectionLength; j++) {
						sections[j].fields = sections[j].fields.filter(field => field.external === null);
					}
					payLoad.layouts[i].sections = sections;
				}
				if ((customData && !customData.showHiddenLayouts) && (preventV1Layouts.indexOf(payLoad.layouts[i].status) !== -1 || preventV2Layouts.indexOf(payLoad.layouts[i].status) !== -1 || (payLoad.layouts[i].source === "campaign_integration" && payLoad.layouts[i].status === 'inactive'))) {
					store.unloadRecord("layout", payLoad.layouts[i].id);
					payLoad.layouts.splice(i, 1);
					i--; payLoadLength--;
					continue;
				}
				payLoad.layouts[i].module = { id: module };
			}
			//			payLoad.layout = payLoad.layouts;
			//			delete payLoad.layouts;
		}
		return payLoad;
	}
}, { mixins: ["crm-crux-module-mixin"] });//No I18n