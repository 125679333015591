//$Id$
//store.unregisterModel("entity");
store.registerModel("entity", { //No I18N	
    type: Lyte.attr('string'), //No I18N
    crmWrapperTest: Lyte.attr('string'), //No I18N
    $approved: Lyte.attr('boolean'), //No I18N
    $notes_view: Lyte.attr('array') // NO I18N
}, {
    actions: {
        count: {}
    }
});