//$Id$
//store.unregisterModel("note")
store.registerModel("note", { //NO i18n
    "id": Lyte.attr("string", { primaryKey: true }), //NO i18n
    "Parent_Id": Lyte.attr("object"), //NO i18n
    "$se_module": Lyte.attr("string"), //NO i18n
    "Note_Title": Lyte.attr("string"), //NO i18n
    "Note_Content": Lyte.attr("string"), //NO i18n
    "$attachments": Lyte.attr("array"), //NO I18n
    "$is_shared_to_client": Lyte.attr("boolean") //NO I18n
});