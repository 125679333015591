//$Id$
//store.unregisterModel("validation_rule")
store.registerModel("validation_rule", {
    active: Lyte.attr('string'), //No I18n
    created_time: Lyte.attr('string'),//No I18N
    execution_type: Lyte.attr('string'),//No I18N
    field_validation: Lyte.attr('boolean'),//NO I18N
    associated_function: Lyte.attr("object"), //No I18N
    generated_type: Lyte.attr('string'),//No I18N
    id: Lyte.attr('string', { primaryKey: true }),//No I18N
    modified_time: Lyte.attr('string'),//No I18N
    source: Lyte.attr('string'),//No I18N
    validation_type: Lyte.attr('string'),//No I18N
    conditions: Lyte.attr('array'),//No I18N

    layout: Lyte.belongsTo("layout"),//No I18n
    modified_by: Lyte.belongsTo('user', { serialize: "record" }), //No I18N
    field: Lyte.belongsTo('field', { serialize: "record" }) //No I18N

}, {
    actions: {

    }
});
