//$Id$
//store.unregisterAdapter("module");
store.registerAdapter("module", {//No I18n
    namespace: "crm/v2.2/settings",//No I18n
    buildURL: function (modelName, type, queryParams, payLoad, url, actionName, customData) {
        url = this.$super.buildURL(modelName, type, queryParams, payLoad, url, actionName, customData);
        if (type === 'action') {
            switch (actionName) {
                case "view_preference_configurations":
                    url = url.replace("v2.2", "v6");
                    var replaceStr = "/settings/modules/"; //No I18n
                    replaceStr = payLoad.id ? replaceStr + payLoad.id + "/" : replaceStr;
                    url = url.replace(replaceStr, "/settings/modules/" + customData.module + "/");
                    return url;
            }
        }
        if (type !== "findAll") {
            var id = url.substring(url.lastIndexOf('/') + 1);
            if (!["ABM_Account__s", "ABM_Segment__s", "ABM_Account_Segment__s"].includes(id)) {
                var moduleName = idModuleMapping[id];
                url = url.replace(id, moduleRecordMapping[moduleName].api_name);
            }
        }
        if (!queryParams) {
            queryParams = {};
        }
        if (type === "findRecord" && !queryParams.include) {
            queryParams.include = ["$properties", "$on_demand_properties", "layouts", "custom_view", "mass_action_cv", "show_social", "show_webform", "show_visitor", "show_googlesync", "show_emailparser",  //No i18n
                "show_phonebridge", "show_salessignals", "show_emailsettings", "show_dashboard", "default_view", "chart_view", "chart_view_supported", "chart_view_cache_supported", "per_page", "filter_status", "kanban_view", "kanban_view_supported", "stage_view", "customized_view", "show_zf_panel", "task_completed_rule_configured", "new_call_view", "zb_custommodule_enabled", //No i18n
                "group_by_field_available", "kanban_feature_status", "kanban_view_fields", "module_mlabel", "module_nlabel", "zia_view", "territory", "related_lists", "related_list_properties", "business_card_fields", "search_layout_fields", "cpq_search_layout_fields", "lookup_field_properties"]; //No i18n
        }
        if (queryParams.include) {
            queryParams.include = queryParams.include.toString();
        }
        return url;
    },
    methodForRequest: function (method, type, queryParams, customData) {
        if (method === "PATCH") {
            return "PUT";//no i18n
        }
        if (type === "action") {
            method = customData && customData.type ? customData.type : method;
        }
        return method;
    },
    processRequest: function (type, modelName, payLoad, snapshot, customData, queryParams, key) {
        if (type === "findRecord") {
            if (customData && customData.getMeta === true) {
                return undefined;
            }
            var _this = this;
            var module = store.peekRecord("module", key).module_name;//No I18n
            if (customData && (customData.getFields === true || customData.layoutId)) {
                return this.fetchFields(module, customData).then(function (data) { //No i18n
                    _this.deleteXHR(module);
                    var a = [];
                    a[0] = data;
                    return JSON.stringify({ modules: a });
                });
            }
            var moduleInfo = store.peekRecord("module", key); //No I18N
            return Lyte.resolvePromises({
                module: store.findRecord("module", key, queryParams, undefined, true, { getMeta: true }),//No I18n
                fields: _this.fetchFields(moduleInfo.module_name, customData)
            }).then(function (res) {
                _this.deleteXHR(module);//No I18n
                res.module[0] = res.module[0].$.toJSON();
                return JSON.stringify({ modules: res.module });
            });
        }
        return undefined;
    },
    fetchFields: function (module, customData) {
        var qp = "";
        if (customData && customData.layoutId) {
            qp = "&layoutId=" + customData.layoutId;//No I18n
            if (customData.mode) {
                qp += "&mode=" + customData.mode;//No i18n
            }
        } else {
            qp = "&getField=true";//No I18n
        }
        var _self = this;
        return new Promise(function (res, rej) {
            var xhr = _self.getXHR(module, customData), url = Crm.getCrmBasePath() + "/ModuleCache.do"; //No I18N
            var reqUrl = url; //No I18N
            xhr.open("GET", reqUrl + "?module=" + module + qp);
            var hd = store.adapter.application.headersForRequest();
            for (var key in hd) {
                xhr.setRequestHeader(key, hd[key]);
            }
            xhr.onload = function () {
                if (this.status >= 200 && this.status < 300) {
                    var clonedData = xhr.response ? Object.assign({}, JSON.parse(xhr.response)) : {};
                    //need to show "lead name" instead of "full name" for leads and contact module alone.
                    var dummyModuleData = store.peekRecord('module', clonedData.id); //no i18n
                    let fullNameField = dummyModuleData && ["Leads", "Contacts"].indexOf(dummyModuleData.api_name) !== -1 && clonedData && clonedData.fields ? clonedData.fields.filter(fld => fld.column_name === "FULLNAME")[0] : undefined;//no i18n
                    if (fullNameField) {
                        fullNameField.field_label = fullNameField.display_label = I18n.getMsg("crm.label.vendor.name", dummyModuleData.singular_label);//no i18n
                    }

                    var mod = store.serializer.module.normalize("module", "findRecord", clonedData, customData); //No I18n
                    if (customData && customData.layoutId) {
                        //only this layout alone sets in relation, other layouts removed from relation
                        dummyModuleData = store.peekRecord('module', mod.id); //no i18n
                        if (dummyModuleData.layouts) {
                            var allLayouts = dummyModuleData.layouts.mapBy('id');//no i18n
                            allLayouts.removeLastOccurenceOfElement(customData.layoutId);//fix for ZCRM-112716
                            mod.layouts = mod.layouts.concat(allLayouts);
                        }
                    }
                    store.pushPayload("module", [mod]); //No I18n
                    res(clonedData);
                } else {
                    rej({
                        status: this.status,
                        statusText: xhr.statusText
                    });
                }
            };
            xhr.onerror = function () {
                rej({
                    status: this.status,
                    statusText: xhr.statusText
                });
            };
            xhr.send();
        });
    },
    getXHR: function (module, customData) {
        if (!this.xhr[module]) {
            this.xhr[module] = new XMLHttpRequest();
        }
        else if (customData && customData.allowMultiple) {
            return new XMLHttpRequest();//eslint-disable-line @zoho/zohocrm/no-deprecated-fnc
        }
        return this.xhr[module];
    },
    xhr: {},
    deleteXHR: function (module) {
        delete this.xhr[module];
    }
}).extends("application"); //NO I18N