//$Id$
//store.unregisterAdapter("application")
store.registerAdapter("application", { //No I18N
    namespace: "crm/v2", //No I18n
    batchNamespace: "batch_requests", //No I18n
    actionNamespace: "actions", //No I18n
    Tab_ID: (function () {
        if (sessionStorage.getItem("TabZID") === null)//NO I18N
        {
            var Session_ID = ($L && $L.moment) ? $L.moment('').toDate().valueOf() : Date.now();
            sessionStorage.setItem("TabZID", Session_ID);//NO I18N
        }
        return sessionStorage.getItem("TabZID");//NO I18N
    })(),

    headersForRequest: function () {
        var headers = {
            "X-ZCSRF-TOKEN": csrfParamName + "=" + csrfToken, //No I18N	
            "X-CRM-ORG": crmZgid, //No I18N	
            "X-CRM-REF-ID": crmZuid + '_' + crmZgid + '_' + this.Tab_ID + '_' + Date.now() //no i18n
        };
        if (typeof crmZgid !== 'undefined' && crmZgid) {
            headers["X-CRM-ORG"] = crmZgid; //No I18N
        }
        return headers;
    },
    buildURL: function (modelName, type, payLoad, queryParams, url) {
        var lastIndex = url.lastIndexOf(modelName);
        if (lastIndex !== -1) {
            url = url.substring(0, lastIndex) + modelName + "s" + url.substring(lastIndex + modelName.length); //No I18N
        }
        return url;
    },
    parseResponse: function (type, modelName, xhr, payload) { //added for payload is coming as "" in lyte version upgrade to 3.0.1
        if (!payload && xhr && xhr.status === 204) {
            return {};
        }
        return payload;
    }

});