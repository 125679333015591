//$Id$
//store.unregisterSerializer("application")
store.registerSerializer("application", {//No I18n
    extractMeta: function (payLoad) {
        if (payLoad && payLoad.info) {
            payLoad.meta = payLoad.info;
            delete payLoad.info;
            return payLoad.meta;
        }
        return undefined;
    },
    serialize: function (type, payLoad) {
        if (type === "batch") { //NO i18n
            payLoad.batch_requests = payLoad.batch;
            delete payLoad.batch;
        }
        return payLoad;
    },
    payloadKey: function (modelName) {
        if (modelName !== 'blueprint') {
            return modelName + 's';//no i18n
        }
    },
    normalizeResponse: function (modelName, type, payLoad) {
        if (payLoad === undefined || payLoad === null || Object.keys(payLoad).length === 0) {
            var res = {};
            if (type === "findAll") {
                res[modelName] = [];
            }
            else if (type === "findRecord") {
                res[modelName] = {};
            }
            return res;
        }
        return payLoad;
    }
});