//$Id$
/*
//Temporary code for testing
let module_tojson = {};
for (var k in moduleRecordMapping) {
    module_tojson[k] = moduleRecordMapping[k].$.toJSON();
}

store.unregisterModel("module");

*/
store.registerModel("module", { //No I18N
    //basic properties
    api_name: Lyte.attr("string"), //No I18N
    id: Lyte.attr("string"), //No I18N
    plural_label: Lyte.attr("string"), //No I18N
    singular_label: Lyte.attr("string"), //No I18N
    module_name: Lyte.attr("string"), //No I18N
    profiles: Lyte.attr("array"), //No I18N

    //relations
    fields: Lyte.hasMany("field", { "deserialize": "record" }), //No I18N
    layouts: Lyte.hasMany("layout"), //No I18N
    related_lists: Lyte.hasMany("related_list", { inverse: "relatedListModule" }), //No I18N

    didLoad: function (record) {
        record = (this instanceof Record) ? this : record;
        var module_id = record.id;
        var moduleName = record.module_name;
        if (moduleName === "Deals") {
            record.module_name = "Potentials";//No I18n
        } if (moduleName === "Meetings") { //No I18n
            record.module_name = "Events";//No I18n
        }
        var field = {};
        var fieldList = record.fields;
        field.id = Lyte.attr('string');//No I18N
        /*
                //temp temp
                store.unregisterModel(module_id);
        */
        if (fieldList && fieldList.length) { //length added since it registers all module's model -- due to new lyte build --lyteCreate
            //added this for handling new fields/section added and we don't reload the page
            if (!store.model[module_id]) {
                let lenF = fieldList.length;
                for (let i = 0; i < lenF; i++) {
                    if (fieldList[i].data_type !== "subform") {
                        field[fieldList[i].api_name] = Lyte.attr(Lyte.registeredMixins["crm-crux-module-mixin"].getfieldattributeType(fieldList[i]));
                        field[fieldList[i].api_name].fieldID = fieldList[i].id;
                        field[fieldList[i].api_name].uiType = fieldList[i].ui_type;
                        field[fieldList[i].api_name].columnName = fieldList[i].column_name;
                        field[fieldList[i].api_name].fieldType = fieldList[i].data_type;
                        field[fieldList[i].api_name].length = fieldList[i].length;
                        field[fieldList[i].api_name].displayLabel = fieldList[i].display_label;
                        field[fieldList[i].api_name].isCustomField = fieldList[i].custom_field;
                        if (fieldList[i].column_name === "PRICINGDETAILS") {
                            field[fieldList[i].api_name].watch = true;
                        }
                    } else {
                        let serializeVal = Crm.userDetails.isSubformNewComponentEnabled || Crm.userDetails.FIELD_OF_LOOKUP_IN_SUBFORM ? "partial" : "record";//no i18n
                        let subform_id = fieldList[i].subform ? fieldList[i].subform.id : fieldList[i].associated_module.id;
                        field[fieldList[i].api_name] = Lyte.hasMany(subform_id, { serialize: serializeVal });
                        field[fieldList[i].api_name].cusRelationFldType = "subform";//ZCRM-193858
                        if (!store.model[subform_id]) {
                            let subfield = {};
                            subfield.__parent_module__ = Lyte.belongsTo(record.id, { inverse: fieldList[i].api_name });
                            if (!fieldList[i].custom_field && ["Invoiced_Items", "Purchase_Items", "Ordered_Items", "Quoted_Items"].includes(fieldList[i].api_name)) {
                                subfield.$parent_line_item_id = Lyte.attr("string");//No i18N
                            }
                            store.registerModel(subform_id, subfield, { extends: 'entity' }); //No I18N
                        }
                        if (!record.currsubformApinames) {
                            record.currsubformApinames = [];
                        }
                        if (!record.currsubformApinames.includes(fieldList[i].api_name)) {
                            record.currsubformApinames.push(fieldList[i].api_name);
                        }
                    }
                }
                field.$state = Lyte.attr("string");//No i18N
                field.$wizard_connection_path = Lyte.attr("array");//No i18N
                field.$validation_rule_action = Lyte.attr("object");//NO I18N
                field.$transitionid = Lyte.attr("string");//No i18N
                if (["Quotes", "Invoices", "SalesOrders", "PurchaseOrders"].includes(moduleName)) {
                    field.$line_tax = Lyte.attr("array", { watch: true });//No i18N
                    field.$parent_inventory_id = Lyte.attr("string");//No i18N
                }
                store.registerModel(module_id, field, { extends: 'entity' }); //No I18N
            } else {
                let lenF = fieldList.length;
                var _model = store.model[module_id];
                if (_model) {
                    for (let i = 0; i < lenF; i++) {
                        if (!_model.fieldList[fieldList[i].api_name] || (_model.fieldList[fieldList[i].api_name].cusRelationFldType !== "subform" && !_model.fieldList[fieldList[i].api_name].fieldType)) { //ZCRM-192913
                            if (fieldList[i].data_type !== "subform") {
                                var k = {};
                                k.type = Lyte.registeredMixins["crm-crux-module-mixin"].getfieldattributeType(fieldList[i]);
                                k.fieldID = fieldList[i].id;
                                k.uiType = fieldList[i].ui_type;
                                k.columnName = fieldList[i].column_name;
                                k.fieldType = fieldList[i].data_type;
                                k.length = fieldList[i].length;
                                k.displayLabel = fieldList[i].display_label;
                                k.isCustomField = fieldList[i].custom_field;
                                if (fieldList[i].column_name === "PRICINGDETAILS") {
                                    k.watch = true;
                                }
                                store.addField(module_id, fieldList[i].api_name, k.type, k, undefined, true);
                            } else {
                                let serializeVal = Crm.userDetails.isSubformNewComponentEnabled || Crm.userDetails.FIELD_OF_LOOKUP_IN_SUBFORM ? "partial" : "record";//No I18N
                                store.addField(module_id, fieldList[i].api_name, Lyte.hasMany(fieldList[i].subform.id, { serialize: serializeVal }));
                                if (_model.fieldList[fieldList[i].api_name]) {
                                    _model.fieldList[fieldList[i].api_name].cusRelationFldType = "subform";//ZCRM-193858
                                }
                                if (!store.model[fieldList[i].subform.id]) {
                                    let subfield = {};
                                    subfield.__parent_module__ = Lyte.belongsTo(record.id, { inverse: fieldList[i].api_name });
                                    if (!fieldList[i].custom_field && ["Invoiced_Items", "Purchase_Items", "Ordered_Items", "Quoted_Items"].includes(fieldList[i].api_name)) {
                                        subfield.$parent_line_item_id = Lyte.attr("string");//No i18N
                                    }
                                    store.registerModel(fieldList[i].subform.id, subfield, { extends: 'entity' }); //No I18N
                                }
                                if (!record.currsubformApinames) {
                                    record.currsubformApinames = [];
                                }
                                if (!record.currsubformApinames.includes(fieldList[i].api_name)) {
                                    record.currsubformApinames.push(fieldList[i].api_name);
                                }
                            }
                        } else {
                            //reset the dynamically changeable properties --- can be modifed via cscript, layout rules ---added for lyteCreate
                            _model.fieldList[fieldList[i].api_name].length = fieldList[i].length;
                        }
                    }
                    if (!_model.fieldList.$state) {
                        store.addField(module_id, '$state', "string");//No i18N
                    }
                    if (!_model.fieldList.$wizard_connection_path) {
                        store.addField(module_id, '$wizard_connection_path', "array");//No i18N
                    }
                    if (!_model.fieldList.$validation_rule_action) {
                        store.addField(module_id, '$validation_rule_action', "object");//No i18N
                    }
                    if (!_model.fieldList.$transitionid) {
                        store.addField(module_id, '$transitionid', "string");//No i18N
                    }
                    if (["Quotes", "Invoices", "SalesOrders", "PurchaseOrders"].includes(moduleName)) {
                        if (!_model.fieldList.$line_tax) {
                            store.addField(module_id, '$line_tax', "array", { type: "array", watch: true });//No i18N
                        }
                        if (!_model.fieldList.$parent_inventory_id) {
                            store.addField(module_id, '$parent_inventory_id', "string");//No i18N
                        }
                    }
                }
            }
        }
        if (validationUtils.isNotEmpty(record.layouts) && record.layouts.length) {
            record.layouts.forEach(function (lay) {
                if ((lay.status >= 0 || lay.status === 'active') && lay.sections && lay.sections.length) {
                    Lyte.registeredMixins["crm-crux-module-mixin"].addSubformFieldsintoLyteModel(lay.sections);//no i18n
                }
            });
        }
    }.on("add")
}, {
    actions: {
        view_preference_configurations: {}
    }
});
/*
for (let k in module_tojson) {
    store.pushPayload('module', module_tojson[k]);
}
*/